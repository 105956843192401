<template>
  <div class="goods">
    <div class="goods-info">
      <div class="radio">
        <img v-if="goods.stock === 0 || goods.amount > goods.stock" src="@/assets/icons/checkround.png">
        <img v-else-if="!goods.checked"  @click="handleSelect" src="@/assets/icons/did_select.png">
        <img v-else  @click="handleSelect" src="@/assets/icons/green_select.png">
      </div>
      <div class="goods-img" @click="goproductDetails(goods)">
        <img :src="`${getProductImageUrl(goods.picturePath,400)}`" alt="" @error="handleImageError" />
        <div v-if="goods.discount > 0" class="tag">
          <div class="top"><span>{{ goods.discount }}</span>%</div>
          <div class="bottom">OFF</div>
        </div>
        <div class="no-store" v-if="goods.stock === 0 || goods.amount > goods.stock">
          {{ $t('庫存不足') }}
        </div>
      </div>
    </div>
    <div class="goods-info-text" @click.stop="goproductDetails(goods)">
      <div class="brand">
        <div class="name">{{ goods.brandName }}</div>
        <van-icon name="delete" class="delete" @click.stop="handleDelete" />
      </div>
      <div class="title">{{ goods.name }}</div>
      <div class="box-tag">
        <span class="tag" v-for="(item,index) in goods?.tags" :key="index">{{ item.tagName }}</span>
      </div>
      <div class="specifications">规格：{{ goods.standard }}</div>
      <div class="priceAndnum">
        <div class="price">{{ formatNumber(goods.unitPrice) }}</div>
        <div class="right">
          <div>
            <img v-if="goods.amount > goods.sellPartCount" @click.stop="handeRemoveAmount(goods)"
              src="@/assets/icons/remove.png" alt="" />
            <img v-else @click.stop="handeRemoveTigs()" src="@/assets/icons/forbidden.png" alt="" />
          </div>
          <div style="line-height: 1">
            <input class="input-number" @click.stop v-model.number="goods.amount" @input="getIsAmount(goods)" type="text" />
          </div>
          <div>
            <img v-if="goods.amount + goods.sellPartCount <= goods.stock" @click.stop="handeAddAmount(goods)"
              src="@/assets/icons/add.png" alt="" />
            <img v-else @click.stop="handeAddTigs()" src="@/assets/icons/addforbidden.png" alt="" />
          </div>
        </div>
      </div>
      <div class="originalCost" v-if="goods.unitPrice < goods.originalPrice">{{ formatNumber(goods.originalPrice) }}
      </div>
    </div>
  </div>
</template>
<script>
import Mixins from "@/utils/mixins"
export default {
  name: "good-items",
  mixins: [Mixins],
  props: {
    objData: {
      type: Object,
      default: {}
    },
  },
  watch: {
    objData: {
      handler (val) {
        this.goods = val
      },
      deep: true,
      immediate: true
    }
  },
  data () {
    return {
      goods: {},
    }
  },
  methods: {
    // 跳转详情
    goproductDetails (data) {
      this.$router.push(`/product/${data.searchProductId}`)

    },
    handeRemoveAmount () {
      if (this.goods.amount <= 1) {
        return
      }
      this.$emit('Remove', this.goods.searchProductId)
    },
    handeAddAmount () {
      this.$emit('Add', this.goods.searchProductId)
    },
    handeAddTigs () {
      this.$toast(this.$t('庫存不足'))
    },
    handeRemoveTigs () {

    },
    getIsAmount (item, type) {
      if (item.amount < 1) {
        item.amount = 1
      }
      this.$emit('getIsAmount', item)
    },
    handleSelect () {
      this.$emit('onSelect', this.goods.searchProductId)
    },
    handleDelete () {
      this.$emit('onDelete', this.goods.searchProductId)
    },
  }
}
</script>
<style lang="scss" scoped>
.radio {
  width: 24px;
  height: 24px;
  font-size: 0;

}

img {
  width: 100%;
  height: 100%;
}

.goods {
  padding: 28px 12px;
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  border-bottom: 1px solid #f8f8f8;

  .goods-info {
    display: flex;
    align-items: center;

    .goods-img {
      margin-left: 8px;
      width: 96px;
      height: 96px;
      background: #F1F1F1;
      overflow: hidden;
      border-radius: 4px;
      position: relative;

      .tag {
        width: 42px;
        height: 42px;
        background: url("~@/assets/image/discounts-tag.png") 100% 100% no-repeat;
        background-size: cover;
        position: absolute;
        left: 0px;
        top: 0;

        .top {
          font-family: OPlusSans 3.0, OPlusSans 30;
          font-weight: bold;
          font-size: 11px;
          color: #FFFFFF;
          line-height: 24px;
          text-shadow: 0px 3px 6px rgba(235, 39, 0, 0.5);
          text-align: center;

          span {
            font-size: 17px;

          }
        }

        .bottom {
          margin-top: -6px;
          font-family: OPlusSans 3.0, OPlusSans 30;
          font-weight: 400;
          font-size: 11px;
          color: #FFFFFF;
          line-height: 16px;
          text-shadow: 0px 3px 6px rgba(235, 39, 0, 0.5);
          text-align: center;
        }
      }

      .no-store {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        font-weight: 400;
        font-size: 12px;
        color: #FFFFFF;
        line-height: 26px;
        text-align: center;
        background: rgba(0, 0, 0, 0.6);
      }
    }
  }

  .goods-info-text {
    margin-left: 12px;
    flex: 1;

    .brand {
      // width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 6px;

      .name {
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 13px;
        color: #1A1A1A;
        line-height: 20px;
        text-align: left;
      }

      .delete {
        font-size: 16px;
        color: #999999;
      }
    }

    .title {
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 13px;
      color: #1A1A1A;
      line-height: 20px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-all;
      margin-bottom: 4px;
    }

    .box-tag {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 4px;

      .tag {
        padding: 0 2px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 11px;
        color: #E84935;
        line-height: 16px;
        border-radius: 2px 2px 2px 2px;
        border: 1px solid rgba(232, 47, 47, 0.5);
      }
    }

    .specifications {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 13px;
      color: #999999;
      line-height: 20px;
      margin-bottom: 8px;
    }
  }

}

.priceAndnum {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .price {
    font-family: PingFang SC, PingFang SC;
    font-weight: 800;
    font-size: 14px;
    color: #F82207;
    line-height: 22px;
  }

  .right {
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px;
    width: auto;

    img {
      width: 20px;
    }

    .input-number {
      width: 30px;
      height: 24px;
      text-align: center;
      background: #f8f8f8;
      margin: 0 8px;
      border-radius: 2px 2px 2px 2px;
    }
  }
}

.originalCost {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #666666;
  line-height: 18px;
  text-align: left;
  text-decoration: line-through;
}
</style>